import React from "react";
import UpcomingShows from "../Components/UpcomingShows";

const Body = () => {
  return (
    <div>
      <div
        className="relative h-64 sm:h-[22rem] md:h-[28rem] lg:h-[44rem] w-full bg-center 
          bg-no-repeat bg-cover opacity-60"
        style={{
          backgroundImage:
            'url("https://s3.ca-central-1.amazonaws.com/assets.sheaclose.com/agravesummer/band_photo.PNG")',
        }}
      >
        <div className="absolute w-full h-full bg-[var(--ags-violet)] opacity-10"></div>
      </div>
      <div
        className="flex flex-col gap-8 my-8 p-8 text-[var(--ags-grey-lightest)] 
          rounded-lg text-lg lg:px-24 md:px-12 sm:px-8 px-4 max-w-7xl m-auto"
      >
        <div className="flex flex-col gap-4 text-justify">
          <p>
            A Grave Summer is a punk and alternative band from Dallas, Texas.
          </p>
          <p>
            Influenced by punk, metal, 90's alternative, and post-punk. Similar
            bands include The Cranberries, Tsunami Bomb, The Smiths, The
            Replacements, INXS, and Pat Benetar.
          </p>
        </div>
        <hr />
        <div className="grid grid-cols-2 gap-8 text-[var(--ags-grey-lightest)] rounded-lg">
          <div className="flex flex-col gap-4 justify-center">
            <p className="text-2xl font-bold">New album available now!</p>
            <p>
              Featuring "Come on, Come On", "Silk Noose", and "Sunny Side". A
              very limited run of 100 copies. Get it before it's gone!
            </p>
          </div>
          <div className="img">
            <img
              src="https://s3.ca-central-1.amazonaws.com/assets.sheaclose.com/agravesummer/cd-mockup.png"
              alt=""
              srcset=""
              className=""
            />
          </div>
        </div>
        <hr />
        <UpcomingShows />
      </div>
    </div>
  );
};
export default Body;
