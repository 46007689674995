const Facebook = ({ width = 40, height = 40, fill = "#DAD6D6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 14"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        d="M5.25 5.25h2.63v2.63H5.25V14H2.62V7.87H0V5.25h2.63v-1.1c0-1.04.32-2.35.97-3.07A3.15 3.15 0 0 1 6.04 0h1.83v2.63H6.04a.79.79 0 0 0-.79.78v1.84Z"
      ></path>
    </svg>
  );
};

export default Facebook;
