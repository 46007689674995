import { useMemo } from "react";
import "./App.css";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import { Outlet, useLocation } from "react-router-dom";

function App() {
  let location = useLocation();
  const shouldRenderHeaderFooter = useMemo(() => {
    return location.pathname !== "/press-kit";
  }, [location?.pathname]);
  return (
    <div>
      <div className="app">
        <div className="app-content">
          {shouldRenderHeaderFooter && <NavBar />}
          <Outlet />
          {shouldRenderHeaderFooter && <Footer />}
        </div>
      </div>
    </div>
  );
}

export default App;
