const Twitter = ({ width = 40, height = 40, fill = "#DAD6D6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 11.36"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        d="M12.36 1.8A2.75 2.75 0 0 0 13.6.22c-.61.32-1.22.54-1.83.67A2.71 2.71 0 0 0 9.7 0c-.81 0-1.5.28-2.06.84A2.77 2.77 0 0 0 6.9 3.5 7.7 7.7 0 0 1 .98.52a2.88 2.88 0 0 0 .88 3.84 3.18 3.18 0 0 1-1.28-.37 2.85 2.85 0 0 0 2.28 2.86c-.24.06-.5.1-.76.1-.24 0-.41-.03-.52-.07a2.84 2.84 0 0 0 2.68 2.01A5.6 5.6 0 0 1 0 10.07a7.92 7.92 0 0 0 4.41 1.29c1.67 0 3.14-.43 4.42-1.27A7.91 7.91 0 0 0 11.66 7c.6-1.22.9-2.48.9-3.8v-.37A6.06 6.06 0 0 0 14 1.33c-.53.24-1.08.39-1.64.47Z"
      ></path>
    </svg>
  );
};

export default Twitter;
