const Youtube = ({ width = 40, height = 40, fill = "#DAD6D6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1 4.5 22 15"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        clipRule="evenodd"
        d="M21.8 5.6c.6.4.8 1.5 1 3 .2 1.5.2 2.4.2 3.4s0 1.9-.2 3.4-.4 2.6-1 3c-.6.5-5.8.6-9.8.6-4 0-9.2-.1-9.8-.6-.6-.4-.8-1.5-1-3C1 13.9 1 13 1 12s0-1.9.2-3.4.4-2.6 1-3C2.8 5.1 8 5 12 5c4 0 9.2.1 9.8.6zM16 12l-6-3.6v7.2l6-3.6z"
      ></path>
    </svg>
  );
};

export default Youtube;
