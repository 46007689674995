import Facebook from "../resources/svgs/facebook";
import Spotify from "../resources/svgs/spotify";
import Twitter from "../resources/svgs/twitter";
import Instagram from "../resources/svgs/insta";
import Youtube from "../resources/svgs/youtube";
import Email from "../resources/svgs/email";

const Footer = () => {
  return (
    <div className="m-auto grid grid-cols-1 gap-8 py-8">
      <div className="flex m-auto justify-evenly max-w-lg w-full h-6">
        <a href="https://facebook.com/agravesummer">
          <Facebook width="24" height="24" fill="var(--ags-violet-lighter)" />
        </a>
        <a href="https://twitter.com/agravesummer">
          <Twitter width="24" height="24" fill="var(--ags-violet-lighter)" />
        </a>
        <a href="https://www.instagram.com/agravesummer">
          <Instagram width="24" height="24" fill="var(--ags-violet-lighter)" />
        </a>
        <a href="https://www.youtube.com/@agravesummer">
          <Youtube width="28" height="28" fill="var(--ags-violet-lighter)" />
        </a>
        <a href="mailto:agravesummer@gmail.com">
          <Email width="28" height="28" fill="var(--ags-violet-lighter)" />
        </a>
        <a href="https://open.spotify.com/artist/5Pl4jxWCyJIfElNzO6JqKS">
          <Spotify width="24" height="24" fill="var(--ags-violet-lighter)" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
