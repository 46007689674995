export const pastShows = [
  {
    venue: "Reno's Chop Shop",
    date: "01/05/2024",
    externalLink: "https://www.facebook.com/events/877421140510622",
    otherBands: ["The Bombs", "Communication Error", "A Grave Summer"],
  },
  {
    venue: "Rubber Gloves",
    date: "07/28/2023",
    externalLink: "https://www.facebook.com/events/911121379945781",
    otherBands: [
      "Richard Haskins (of The WeeBeasties)",
      "A Grave Summer",
      "Super Hero/Super Villain",
    ],
  },
];

const upcomingShows = [];

export default upcomingShows;
