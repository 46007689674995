import React, { useEffect, useState } from "react";
import bandPhotos from "../resources/bandPhotoCarousel.json";
import classNames from "classnames";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInfiniteScrol = () => {
    if (currentIndex === bandPhotos.length - 1) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };
  useEffect(() => {
    const interval = setInterval(() => carouselInfiniteScrol(), 5000);
    return () => clearInterval(interval);
  });
  return (
    <div className="flex flex-nowrap overflow-hidden">
      {bandPhotos.map(({ imageUrl, position }) => {
        return (
          <div
            className="min-w-full w-full flex items-center justify-center duration-1000 ease-in"
            key={imageUrl}
            style={{ transform: `translate(-${currentIndex * 100}%)` }}
          >
            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
              <img
                src={imageUrl}
                className={classNames(
                  "rounded-lg border-2 aspect-square object-cover",
                  {
                    "object-top": position === "top",
                  }
                )}
                alt="band standing there"
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Carousel;
