const Instagram = ({ width = 40, height = 40, fill = "#DAD6D6" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="3 3 18 18"
      width={width}
      height={height}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3h12c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3H6c-1.7 0-3-1.3-3-3V6c0-1.7 1.3-3 3-3zm9 9c0-1.7-1.3-3-3-3s-3 1.3-3 3 1.3 3 3 3 3-1.3 3-3zm.8-5c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zM5 18c0 .6.4 1 1 1h12c.6 0 1-.4 1-1v-6h-2c0 2.8-2.2 5-5 5s-5-2.2-5-5H5v6z"
      ></path>
    </svg>
  );
};

export default Instagram;
