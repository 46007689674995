import { Link } from "react-router-dom";
import AgsLogo from "../resources/svgs/ags-logo";

const NavBar = () => {
  const locations = [
    {
      label: "Music",
      link: "/music",
    },
    {
      label: "Gallery",
      link: "/gallery",
    },
  ];
  return (
    <div className="p-6 flex flex-col gap-2 w-full absolute top-0 bg-transparent z-10 text-xl">
      <div className="flex w-full flex-wrap gap-x-2 sm:gap-x-4 md:gap-x-8 lg:gap-x-12 justify-center sm:justify-between items-center px-4">
        <Link to="/">
          <div className="flex w-full justify-end md:justify-normal items-center">
            <div className="block">
              <AgsLogo width={80} height={80} />
            </div>
            <div className="hidden md:block pl-2 font-semibold">
              A Grave Summer
            </div>
          </div>
        </Link>
        <div className="flex flex-wrap gap-2 sm:gap-4 md:gap-8 lg:gap-12 justify-between md:justify-normal">
          {locations.map((location) => {
            return (
              <div className="underline font-semibold" key={location.label}>
                {location.label === "Gallery" ? (
                  <a href={location.link}>{location.label}</a>
                ) : (
                  <Link to={location.link}>{location.label}</Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
