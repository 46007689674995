import { Link } from "react-router-dom";
import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import playlist from "../resources/playlist.json";
import Carousel from "../Components/Carousel";
import Facebook from "../resources/svgs/facebook";
import Twitter from "../resources/svgs/twitter";
import Instagram from "../resources/svgs/insta";
import Youtube from "../resources/svgs/youtube";
import Email from "../resources/svgs/email";
import AgsLogo from "../resources/svgs/ags-logo";
import UpcomingShows from "../Components/UpcomingShows";
import Spotify from "../resources/svgs/spotify";

const Contact = () => {
  const [current, setCurrent] = useState(playlist[0]);
  const getCurrentIndex = () => {
    return playlist.findIndex((song) => song.title === current.title);
  };
  const onClickPrevious = () => {
    const index = getCurrentIndex();
    if (index === 0) return;
    return setCurrent(playlist[index - 1]);
  };
  const onClickNext = () => {
    const index = getCurrentIndex();
    if (index === playlist.length - 1) return;
    return setCurrent(playlist[index + 1]);
  };
  return (
    <div id="EPK" className="grid grid-cols-1 gap-12">
      {/* Header */}
      <div className="bg-[var(--ags-violet-darker-still)]">
        <div id="Header" className="m-auto p-8">
          <div className="flex flex-col gap-4 items-center justify-center text-6xl font-semibold">
            A Grave Summer
          </div>
        </div>
      </div>
      {/* Bio/Music */}
      <div>
        <div className="xl:px-0 md:px-12 px-8 max-w-6xl grid m-auto sm:grid-cols-1 lg:grid-cols-[500px_1fr] gap-16">
          {/* Bio */}
          <div className="text-xl">
            <p className="font-extrabold mb-4">Bio</p>
            <div className="font-normal grid gap-4">
              <p>
                A Grave Summer is a punk and alternative band from Dallas,
                Texas.
              </p>
              <p>
                The members of A Grave Summer have a rich musical history. Shea
                (bass guitar/backing vocals) and Micko (drums) played together
                in “Dog Company”. Kevin (lead guitar) and Shea played together
                in “The Last of the Interceptors”. Micko and Jake (guitar)
                played together in “With These Words”. Lorena (vocalist) is a
                late addition, having been found through a musician search.
              </p>
              <p>
                A Grave Summer is influenced by punk, metal, and post-punk.
                Similar bands might include The Cranberries, Tsunami Bomb, The
                Smiths, The Replacements, INXS, and Pat Benetar.
              </p>
            </div>
          </div>
          {/* Music */}
          <div>
            <p className="text-xl font-extrabold mb-4">Music</p>
            <div className="flex flex-col gap-4">
              <iframe
                width="100%"
                style={{
                  aspectRatio: "1.5",
                }}
                src="https://www.youtube.com/embed/UIycbiwrFzA?si=ntAbzFz1Pa-KXWrP"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <AudioPlayer
                onClickPrevious={onClickPrevious}
                onClickNext={onClickNext}
                header={<div>{current.title}</div>}
                src={current.url}
                showSkipControls
              />
            </div>
            <style jsx="true">{`
              .rhap_download-progress {
                background-color: var(--ags-violet-light);
              }
              .rhap_header {
                color: var(--ags-grey);
              }
              .rhap_volume-bar-area .rhap_volume-bar {
                background-color: var(--ags-grey-light);
              }
              .rhap_container {
                background-color: var(--ags-black);
                border-color: var(--ags-grey-darkest);
                border-radius: 6px;
                border-width: 1px;

                .rhap_button-clear svg {
                  color: var(--ags-grey-dark);
                }
                .rhap_time.rhap_current-time {
                  color: var(--ags-grey-darker);
                }
              }
              .rhap_progress-indicator {
                background-color: var(--ags-green);
              }
              .rhap_progress-filled {
                background-color: var(--ags-green-darkest);
              }
              .rhap_progress-bar-show-download {
                background-color: var(--ags-violet-darkest);
              }
            `}</style>
          </div>
        </div>
      </div>
      {/* Photos/Dates */}
      <div className="bg-[var(--ags-grey-darkest)] py-8">
        <div className="xl:px-0 md:px-12 px-8 max-w-6xl grid m-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-[1fr_400px] gap-6">
          <div className="grid gap-4 grid-rows-[26px_1fr]">
            <p className="text-xl font-extrabold">Shows</p>
            <UpcomingShows />
          </div>
          <div className="grid gap-4 grid-rows-[26px_1fr]">
            <p className="text-xl font-extrabold">Photos</p>
            <Carousel />
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="pb-8">
        <div className="xl:px-0 md:px-12 px-8 max-w-6xl m-auto grid sm:grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="grid grid-cols-2 gap-2">
            <div className="grid grid-cols-1 gap-2">
              <a
                href="https://www.facebook.com/agravesummer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="font-extrabold">FOLLOW US</div>
              </a>
              <hr className="w-1/2" />
              <a href="mailto:agravesummer@gmail.com">
                <div className="font-extrabold">CONTACT US</div>
              </a>
            </div>
            <div className="flex m-auto justify-evenly max-w-lg w-full h-6">
              <a href="https://facebook.com/agravesummer">
                <Facebook
                  width="24"
                  height="24"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
              <a href="https://twitter.com/agravesummer">
                <Twitter
                  width="24"
                  height="24"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
              <a href="https://www.instagram.com/agravesummer">
                <Instagram
                  width="24"
                  height="24"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
              <a href="https://www.youtube.com/@agravesummer">
                <Youtube
                  width="28"
                  height="28"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
              <a href="mailto:agravesummer@gmail.com">
                <Email
                  width="28"
                  height="28"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
              <a href="https://open.spotify.com/artist/5Pl4jxWCyJIfElNzO6JqKS">
                <Spotify
                  width="24"
                  height="24"
                  fill="var(--ags-violet-lighter)"
                />
              </a>
            </div>
          </div>
          <div className="flex justify-center lg:justify-end items-center">
            <Link to="/">
              <AgsLogo width={80} height={80} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
