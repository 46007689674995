import upComingShows, { pastShows } from "../resources/upcomingshows.js";
import classNames from "classnames";
const UpcomingShows = () => {
  const shows = upComingShows.length ? upComingShows : pastShows;
  return (
    <div className="flex flex-col gap-8 items-center">
      <p className="text-2xl font-bold">
        {upComingShows.length ? "Upcoming Dates" : "Past Shows"}
      </p>
      <div
        className={classNames("grid gap-4", {
          "grid-cols-1": !shows.length,
          "md:grid-cols-2 lg:grid-cols-3 grid-cols-1": shows.length,
        })}
      >
        {shows.map(({ externalLink, venue, date, otherBands }) => (
          <div key={date} className="p-2 border rounded-md hover:opacity-60">
            <a href={externalLink} target="_blank" rel="noopener noreferrer">
              <div className="flex flex-col gap-4">
                <div className="font-bold"> {date} </div>
                <div className="underline"> {venue}</div>
                <div>
                  {otherBands?.map((c) => (
                    <div key={c} className="ml-2">
                      • {c}
                    </div>
                  ))}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingShows;
