import React, { useState } from "react";
import playlist from "../resources/playlist.json";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const Music = () => {
  const [current, setCurrent] = useState(playlist[0]);
  const getCurrentIndex = () => {
    return playlist.findIndex((song) => song.title === current.title);
  };
  const onClickPrevious = () => {
    const index = getCurrentIndex();
    if (index === 0) return;
    return setCurrent(playlist[index - 1]);
  };
  const onClickNext = () => {
    const index = getCurrentIndex();
    if (index === playlist.length - 1) return;
    return setCurrent(playlist[index + 1]);
  };
  return (
    <div className="lg:px-24 md:px-12 sm:px-8 px-4 max-w-7xl m-auto grid grid-cols-1 gap-8 pt-32 pb-8">
      <div className="flex flex-col max-w-7xl">
        <div className="font-bold py-4">Sound The Goodbye</div>
        <div className="desktop-playlist flex flex-wrap">
          <div
            className="lg:w-5/12 xl:1/3 md:w-1/2 w-full h-0 pb-[50%] md:pb-[60%] lg:pb-[48%] xl:pb-[37%] position bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage:
                'url("https://s3.ca-central-1.amazonaws.com/assets.sheaclose.com/agravesummer/AGS+Cover.jpeg")',
            }}
          />
          <div className="lg:w-7/12 xl:2/3 md:w-1/2 w-full hidden md:block bg-[var(--ags-grey-lightest)]">
            {playlist.map(({ title, runtime }, index) => (
              <div
                key={title}
                className="odd:bg-[var(--ags-grey)] odd:text-[var(--ags-black)] even:bg-[var(--ags-white)] even:text-[var(--ags-black)] px-4 py-2"
              >
                <div
                  className="cursor-pointer flex justify-between"
                  onClick={() => setCurrent(playlist[index])}
                >
                  <span>
                    {index + 1}. {title}
                  </span>
                  <span className="text-[var(--ags-grey-darkest)]">
                    ({runtime})
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <AudioPlayer
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
          header={<div>{current.title}</div>}
          src={current.url}
          showSkipControls
        />
        <style jsx>{`
          .rhap_download-progress {
            background-color: var(--ags-violet-light);
          }
          .rhap_header {
            color: var(--ags-grey);
          }
          .rhap_volume-bar-area .rhap_volume-bar {
            background-color: var(--ags-grey-light);
          }
          .rhap_container {
            background-color: var(--ags-black);
            border-color: var(--ags-grey-darkest);
            border-radius: 6px;
            border-width: 1px;

            .rhap_button-clear svg {
              color: var(--ags-grey-dark);
            }
            .rhap_time.rhap_current-time {
              color: var(--ags-grey-darker);
            }
          }
          .rhap_progress-indicator {
            background-color: var(--ags-green);
          }
          .rhap_progress-filled {
            background-color: var(--ags-green-darkest);
          }
          .rhap_progress-bar-show-download {
            background-color: var(--ags-violet-darkest);
          }
        `}</style>
      </div>
      <hr />
      <div className="videos w-full grid sm:grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <iframe
          width="100%"
          style={{
            aspectRatio: "1.5",
          }}
          src="https://www.youtube.com/embed/stkVRzpnlXg?si=wUCL-vQj1kpEdFZ7"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <iframe
          width="100%"
          style={{
            aspectRatio: "1.5",
          }}
          src="https://www.youtube.com/embed/o_xZ3rY46Es?si=XVwXQii2LuNb25Jw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <iframe
          width="100%"
          style={{
            aspectRatio: "1.5",
          }}
          src="https://www.youtube.com/embed/7OoWQSWP1W8?si=L3MhVQs9C1YDG5PZ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Music;
