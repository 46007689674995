import React from "react";

const AgsLogo = ({ width = 40, height = 40, fill = "#DAD6D6" }) => (
  <svg width={width} height={height} viewBox="0 0 231 179">
    <defs id="defs860" />
    <g>
      <path
        style={{ fill }}
        d="M 32.25,152.83406 C 29.9125,152.31059 28,151.56837 28,151.18468 28,150.801 40.949699,137.54314 56.77711,121.72279 l 28.777109,-28.764276 5.472891,4.874954 c 7.905212,7.041532 12.60658,9.171312 20.18305,9.143172 7.11839,-0.0264 12.12327,-2.37163 20.5047,-9.608108 L 136.5,93.237064 165.77736,121.86853 c 16.10254,15.74731 29.26504,28.94782 29.25,29.33447 -0.0938,2.41152 -11.5065,2.7805 -83.02736,2.68433 -43.725415,-0.0588 -77.288636,-0.50207 -79.75,-1.05327 z M 14.003267,134.75 C 12.41512,127.43723 13.009109,28.331412 14.65782,25.539024 c 1.055919,-1.788389 3.740263,0.607065 30.5,27.217579 L 74.5,81.935159 45.506466,110.96758 C 29.560022,126.93541 16.204795,140 15.828182,140 c -0.376612,0 -1.197824,-2.3625 -1.824915,-5.25 z M 176.49353,110.92516 147.5,81.850312 177,52.795201 l 29.5,-29.055112 1.17315,3.879956 c 0.82644,2.733282 1.19421,18.804607 1.24442,54.379955 0.0669,47.38618 -0.43106,58 -2.72104,58 -0.3902,0 -13.75655,-13.08368 -29.703,-29.07484 z M 105.33955,89.714565 C 102.5803,88.163373 27,14.092336 27,12.939384 c 0,-2.66065 10.319329,-2.9899028 85.21669,-2.718959 62.32181,0.225451 77.86409,0.541288 80.28331,1.631448 l 3,1.351873 -37.5,36.981402 c -20.625,20.339772 -38.85,37.842247 -40.5,38.894389 -3.38624,2.159281 -8.93382,2.448979 -12.16045,0.635028 z"
        id="path1090"
      />
    </g>
  </svg>
);

export default AgsLogo;
