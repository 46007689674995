import React from "react";

const AgsLogo = ({ width = 40, height = 40, fill = "#DAD6D6" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 210 297"
    version="1.1"
    id="svg5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2"></defs>
    <g id="layer1">
      <g
        id="g33"
        style={{ fill }}
        transform="matrix(0.88450875,0,0,0.88450875,-461.0856,-156.75724)"
      >
        <g id="g19" style={{ fill }}>
          <g id="g13" style={{ fill }}>
            <path
              className="st0"
              d="m 640,448.1 c -44.39,0 -80.5,-36.11 -80.5,-80.5 0,-44.39 36.11,-80.5 80.5,-80.5 21.5,0 41.72,8.37 56.92,23.58 l -7.07,7.07 C 676.53,304.43 658.83,297.1 640,297.1 c -38.87,0 -70.5,31.63 -70.5,70.5 0,38.87 31.63,70.5 70.5,70.5 37.19,0 67.75,-28.95 70.32,-65.5 h -32.34 v -10 h 42.52 v 5 c 0,44.39 -36.11,80.5 -80.5,80.5 z"
              id="path11"
              style={{ fill }}
            />
          </g>

          <g id="g17" style={{ fill }}>
            <path
              className="st0"
              d="m 640,410.59 c -11.48,0 -22.28,-4.47 -30.39,-12.59 l 7.07,-7.07 c 6.23,6.23 14.51,9.66 23.32,9.66 16.49,0 30.19,-12.16 32.61,-27.98 h -75.59 v -5 c 0,-23.7 19.28,-42.98 42.98,-42.98 11.48,0 22.28,4.47 30.39,12.59 l -7.07,7.07 c -6.23,-6.23 -14.51,-9.66 -23.32,-9.66 -16.49,0 -30.19,12.16 -32.61,27.98 h 75.59 v 5 c 0,23.69 -19.28,42.98 -42.98,42.98 z"
              id="path15"
              style={{ fill }}
            />
          </g>
        </g>

        <g id="g23" style={{ fill }}>
          <polygon
            className="st0"
            points="688.65,430.34 688.65,420.34 731.34,420.34 710,383.38 718.67,378.38 748.66,430.34 "
            id="polygon21"
            style={{ fill }}
          />
        </g>

        <g id="g27" style={{ fill }}>
          <polygon
            className="st0"
            points="531.34,430.34 561.34,378.38 570,383.38 548.66,420.34 591.35,420.34 591.35,430.34 "
            id="polygon25"
            style={{ fill }}
          />
        </g>

        <g id="g31" style={{ fill }}>
          <polygon
            className="st0"
            points="640,262.13 618.67,299.08 610.01,294.08 640,242.13 669.99,294.08 661.33,299.08 "
            id="polygon29"
            style={{ fill }}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default AgsLogo;
